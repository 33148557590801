import { Stack, Typography } from '@mui/material';

import { t } from 'i18next';

export const MeetingsInfo = () => {
  return (
    <Stack gap={2}>
      <Typography variant="h4" fontWeight={500}>
        {t('meetings_page.heading')}
      </Typography>
      <Typography variant="subtitle2" fontSize={16}>
        {t('meetings_page.sub_header')}
      </Typography>
      <Typography variant="body2">{t('meetings_page.instruction_text_part1')}</Typography>
      <Typography variant="body2">{t('meetings_page.instruction_text_part2')}</Typography>
      <Typography variant="body2">{t('meetings_page.instruction_text_part3')}</Typography>
    </Stack>
  );
};
