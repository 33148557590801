import { OwnerType, UnderlyingShareholder } from 'types/attendee';
import { mapOwnerTypeToNumber } from 'types/meeting';

export interface Agenda {
  agendaId: string; // do we actually need it?
  isFinal: boolean;
  agendaItems: AgendaItem[];
}

export interface AgendaItem {
  agendaItemId: string;
  index: string;
  displayIndex: string;
  isResolution: boolean;
  englishContent: AgendaItemContent;
  swedishContent: AgendaItemContent;
}

export interface AgendaItemContent {
  option1: string;
  option2: string;
  option3: string;
  question: string;
  suggestion: string;
}

export interface Voting {
  attendeeId: number;
  givenName: string;
  surName: string;
  ownerType: OwnerType;
  isRepresentingOwnShares: boolean;
  pin: string;
  underlyingShareholders: UnderlyingShareholder[];
  votes: Vote[];
}

export interface VotingDto {
  attendeeId: number;
  firstName: string;
  lastName: string;
  ownerType: number;
  isRepresentingOwnShares: boolean;
  pin: string;
  underlyingShareholders: UnderlyingShareholder[];
  votes: Vote[];
}

export interface Vote {
  id: string;
  option: string;
}

export function isAllOptionsEmpty(content: AgendaItemContent) {
  const { option1, option2, option3 } = content;
  return !option1 && !option2 && !option3;
}

export function getContentForLocale(locale: string, agendaItem: AgendaItem) {
  if (locale === 'sv') {
    return agendaItem.swedishContent;
  }

  return agendaItem.englishContent;
}

export function mapVotingToDto(voting: Voting): VotingDto {
  return {
    attendeeId: voting.attendeeId,
    firstName: voting.givenName,
    lastName: voting.surName,
    ownerType: mapOwnerTypeToNumber(voting.ownerType),
    isRepresentingOwnShares: voting.isRepresentingOwnShares,
    pin: voting.pin.replace('-', ''),
    underlyingShareholders: mapUnderlyingToDto(voting.underlyingShareholders) ?? [],
    votes: voting.votes
  };
}

function mapUnderlyingToDto(underlyingShareholders: UnderlyingShareholder[]): UnderlyingShareholder[] {
  return underlyingShareholders.map(underlying => {
    return {
      ...underlying,
      personOrOrganizationNumber: underlying.personOrOrganizationNumber.replace('-', '')
    };
  });
}
