import { useQuery } from '@tanstack/react-query';
import { Agenda, AgendaItem } from 'types/agenda';
import { api } from 'utils/fetch';

export function useAgendaQuery(meetingId?: number) {
  return useQuery({
    queryKey: ['agenda', meetingId],
    queryFn: () => getAgenda(meetingId)
  });
}

async function getAgenda(meetingId?: number): Promise<Agenda> {
  const response = await api.get(`/external/meetings/${meetingId}/agenda`);
  return mapAgendaResponse(response.data);
}

function mapAgendaResponse(agenda: Agenda): Agenda {
  return {
    agendaId: agenda.agendaId,
    isFinal: agenda.isFinal,
    agendaItems: sortAgendaItemsByIndex(agenda.agendaItems)
  };
}

function sortAgendaItemsByIndex(items: AgendaItem[]): AgendaItem[] {
  return items.sort((a, b) => {
    const itemAIndexes = a.index.split('.').map(Number);
    const itemBIndexes = b.index.split('.').map(Number);

    for (let i = 0; i < Math.max(itemAIndexes.length, itemBIndexes.length); i++) {
      const itemAIndex = itemAIndexes[i] ?? 0;
      const itemBIndex = itemBIndexes[i] ?? 0;

      if (itemAIndex !== itemBIndex) {
        return itemAIndex - itemBIndex;
      }
    }

    return 0;
  });
}
