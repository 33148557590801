const formatPINAndOrgNr = (pinOrOrgNr: string | null): string => {
  if (!pinOrOrgNr) {
    return ''; // If null
  }
  if (pinOrOrgNr.length === 10) {
    return addDashToPINAndOrgNr(pinOrOrgNr);
  }
  if (pinOrOrgNr.length === 12) {
    switch (true) {
      case pinOrOrgNr.startsWith('19'):
      case pinOrOrgNr.startsWith('18'):
      case pinOrOrgNr.startsWith('20'):
        return addDashToPIN(pinOrOrgNr);
      case pinOrOrgNr.startsWith('15'):
      case pinOrOrgNr.startsWith('16'):
        return addDashToPINAndOrgNr(pinOrOrgNr.slice(2));
      default:
        return pinOrOrgNr;
    }
  }

  return pinOrOrgNr;
};
//YYMMDD-XXXX FORMAT
const addDashToPINAndOrgNr = (pinOrOrgNr: string): string => {
  return pinOrOrgNr.slice(0, 6) + '-' + pinOrOrgNr.slice(6);
};
//YYYYMMDD-XXXX FORMAT
const addDashToPIN = (pinOrOrgNr: string): string => {
  return pinOrOrgNr.slice(0, 8) + '-' + pinOrOrgNr.slice(8);
};
export default formatPINAndOrgNr;
