import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material/';
import { FlagGB } from 'components/FlagGB';
import { FlagSE } from 'components/FlagSE';
import { useLocale } from 'hooks/useLocale';
import { Link } from 'react-router-dom';

export const TopNav = () => {
  const { locale, toggleLocale } = useLocale();

  const renderFlag = () => {
    return <Button onClick={toggleLocale}>{locale === 'sv' ? <FlagSE /> : <FlagGB />}</Button>;
  };

  return (
    <AppBar>
      <Toolbar>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <img src="/assets/images/euroclear-logo.svg" title="Euroclear" height={'48'} width={'48'} />
            <Typography variant="h6" component={Link} to="/" sx={{ textDecoration: 'none', color: 'white' }}>
              Euroclear
            </Typography>
          </Box>

          {renderFlag()}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
