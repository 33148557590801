import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IAttendee, mapAttendeeToAttendeeDto } from 'types/attendee';
import { api } from 'utils/fetch';

export function useRegistrationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['registration'],
    mutationFn: (attendee: IAttendee) => postMeetingRegistration(attendee),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['meetings'] });
    }
  });
}

async function postMeetingRegistration(attendee: IAttendee) {
  return await api.post(`/external/meetingattendee`, mapAttendeeToAttendeeDto(attendee));
}
