import { AuthProvider } from 'providers/auth';
import { MuiThemeProvider } from 'providers/mui';
import { ReactQueryProvider } from 'providers/react-query';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const Providers: FC<Props> = ({ children }) => {
  return (
    <MuiThemeProvider>
      <ReactQueryProvider>
        <AuthProvider>{children}</AuthProvider>
      </ReactQueryProvider>
    </MuiThemeProvider>
  );
};
