import { Layout } from 'components/Layout';
import ErrorPage from 'pages/404';
import MeetingPage from 'pages/MeetingPage';
import MeetingsPage from 'pages/MeetingsPage';
import RegistrationPage from 'pages/RegisterPage';
import VotePage from 'pages/VotePage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const Routes = () => {
  const routes = [
    {
      path: '/',
      element: <MeetingsPage />
    },
    {
      path: '/meeting/:meetingId',
      children: [
        {
          path: '',
          element: <MeetingPage />
        },
        {
          path: '/meeting/:meetingId/register',
          element: <RegistrationPage />
        },
        {
          path: '/meeting/:meetingId/register/proxy',
          element: <RegistrationPage />
        },
        {
          path: '/meeting/:meetingId/vote',
          element: <VotePage />
        }
      ]
    },

    { path: '*', element: <ErrorPage /> }
  ];

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: routes
    }
  ]);

  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
};

export default Routes;
