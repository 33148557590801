import { useCallback, useEffect, useState } from 'react';
import { IMeeting, SortBy, Status, Type } from 'types/meeting';

interface UseFilterMeetingsProps {
  meetings?: IMeeting[];
  searchString: string | null;
  filters: MeetingFilters;
  sortBy: SortBy;
}

interface MeetingFilters {
  meetingType: Type;
  meetingStatus: Status;
}

export const useFilterMeetings = ({ meetings, searchString, filters, sortBy }: UseFilterMeetingsProps) => {
  const [filteredMeetings, setFilteredMeetings] = useState<IMeeting[] | undefined>([]);
  const { meetingStatus, meetingType } = filters;

  const sortMeetings = useCallback((meetings: IMeeting[], sortBy: SortBy) => {
    return meetings.sort((a, b) => {
      switch (sortBy) {
        case SortBy.ClientName:
          return a.clientName.localeCompare(b.clientName);
        case SortBy.MeetingDate:
          return sortByDate(a.startTime, b.startTime);
        case SortBy.DeadlineNotification:
          return sortByDate(a.lastRegistrationDate, b.lastRegistrationDate);
        case SortBy.DeadlinePostalVote:
          return sortByDate(a.lastPostalVotingDate, b.lastPostalVotingDate);
        default:
          return -1;
      }
    });
  }, []);

  useEffect(() => {
    if (meetings === undefined) {
      setFilteredMeetings(meetings);
      return;
    }

    const filteredBySearch = filterMeetingsBySearchString(meetings, searchString);
    const filteredByOptions = filterMeetingsByFilterOptions(filteredBySearch, { meetingStatus, meetingType });
    const sortedMeetings = sortMeetings(filteredByOptions, sortBy);

    setFilteredMeetings(sortedMeetings);
  }, [meetings, searchString, meetingStatus, meetingType, sortBy, sortMeetings]);

  function filterMeetingsBySearchString(meetings: IMeeting[], searchString: string | null): IMeeting[] {
    if (searchString === null) {
      return meetings;
    }

    return meetings.filter(({ clientName }) => clientName.toLowerCase().includes(searchString.toLowerCase()));
  }

  function filterMeetingsByFilterOptions(meetings: IMeeting[], filters: MeetingFilters): IMeeting[] {
    const { meetingStatus, meetingType } = filters;

    return meetings.filter(({ type, status }) => {
      return type === meetingType && status === meetingStatus;
    });
  }

  function sortByDate(a: Date | null, b: Date | null): number {
    if (a === null && b === null) {
      return 0;
    } else if (a === null) {
      return 1;
    } else if (b === null) {
      return -1;
    }

    return a.getTime() - b.getTime();
  }

  return { filteredMeetings };
};
