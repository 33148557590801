import ClearRounded from '@mui/icons-material/ClearRounded';
import { Button, IconButton, List, ListItem, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Organisationsnummer from 'organisationsnummer';
import Personnummer from 'personnummer';
import { ErrorRes, usePersonCanbeUnderlying } from 'queries/usePersonCanBeUnderlying';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UnderlyingShareholder } from 'types/attendee';
import formatPINAndOrgNr from 'utils/pin';
import { normalizeKey } from 'utils/translation';
import validatePinAndOrgNumber from 'utils/validatePinAndOrgNumber';

interface Props {
  fields: {
    firstName: string;
    lastName: string;
    personOrOrganizationNumber: string;
    id: string;
  }[];
  append: (value: { firstName: string; lastName: string; personOrOrganizationNumber: string }) => void;
  remove: (index: number) => void;
  proxyId: number;
}

export const AddUnderlyingShareholders = ({ fields, append, remove, proxyId }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const meetingId = Number(params.meetingId);
  const [errorResponse, setErrorResponse] = useState<ErrorRes | undefined>(undefined);
  const [personCanBeAdded, setPersonCanBeAdded] = useState<boolean | null>(null);
  const [underlyingFields, setUnderlyingFields] = useState<UnderlyingShareholder>({
    firstName: '',
    lastName: '',
    personOrOrganizationNumber: ''
  });
  const [underlyingFieldsFieldsErrors, setUnderlyingFieldsFieldsErrors] = useState<UnderlyingShareholder>({
    firstName: '',
    lastName: '',
    personOrOrganizationNumber: ''
  });
  const { data } = usePersonCanbeUnderlying(meetingId, underlyingFields.personOrOrganizationNumber, proxyId);

  useEffect(() => {
    if (typeof data === typeof true) {
      setPersonCanBeAdded(data as boolean);
    }
    setErrorResponse(data as ErrorRes);
  }, [data]);

  const disableAdd: boolean =
    !personCanBeAdded ||
    !!fields.find(
      x =>
        x.personOrOrganizationNumber.replace('-', '') === underlyingFields.personOrOrganizationNumber.replace('-', '')
    );

  const handleAddUnderlying = () => {
    setUnderlyingFieldsFieldsErrors({ personOrOrganizationNumber: '', firstName: '', lastName: '' });
    if (isUnderlyingFieldsErrors()) {
      return;
    }
    append(underlyingFields);
    setUnderlyingFields({ firstName: '', lastName: '', personOrOrganizationNumber: '' });
  };

  const isUnderlyingFieldsErrors = () => {
    let hasErrors = false;
    if (
      validatePinAndOrgNumber(underlyingFields.personOrOrganizationNumber) &&
      underlyingFields.firstName.length > 0 &&
      underlyingFields.lastName.length > 0
    ) {
      setUnderlyingFieldsFieldsErrors({ personOrOrganizationNumber: '', firstName: '', lastName: '' });

      return false;
    }
    if (!validatePinAndOrgNumber(underlyingFields.personOrOrganizationNumber)) {
      setUnderlyingFieldsFieldsErrors(prev => ({ ...prev, personOrOrganizationNumber: 'invalidPin' }));
      hasErrors = true;
    }

    if (
      !(
        Organisationsnummer.valid(underlyingFields.personOrOrganizationNumber) &&
        !Personnummer.valid(underlyingFields.personOrOrganizationNumber)
      ) &&
      underlyingFields.firstName.length == 0
    ) {
      setUnderlyingFieldsFieldsErrors(prev => ({ ...prev, firstName: 'required' }));
      hasErrors = true;
    }

    if (underlyingFields.lastName.length == 0) {
      setUnderlyingFieldsFieldsErrors(prev => ({ ...prev, lastName: 'required' }));
      hasErrors = true;
    }

    return hasErrors;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12}>
          <TextField
            sx={{ backgroundColor: 'white' }}
            placeholder={t('orgpnr')}
            name="pnr"
            autoComplete="pnr"
            size="small"
            fullWidth
            value={
              underlyingFields.personOrOrganizationNumber
                ? formatPINAndOrgNr(underlyingFields.personOrOrganizationNumber.replace('-', ''))
                : ''
            }
            onChange={event =>
              setUnderlyingFields(prev => ({ ...prev, personOrOrganizationNumber: event.target.value }))
            }
            error={
              !!underlyingFieldsFieldsErrors.personOrOrganizationNumber ||
              !!(
                underlyingFields.personOrOrganizationNumber &&
                !validatePinAndOrgNumber(underlyingFields.personOrOrganizationNumber)
              )
            }
            helperText={
              !!underlyingFieldsFieldsErrors.personOrOrganizationNumber ||
              !!(
                underlyingFields.personOrOrganizationNumber &&
                !validatePinAndOrgNumber(underlyingFields.personOrOrganizationNumber)
              )
                ? t('registration_page.error.invalid_pin_orgnumber')
                : null
            }
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <TextField
            sx={{ backgroundColor: 'white' }}
            placeholder={t('firstname')}
            name="fname"
            autoComplete="fname"
            size="small"
            fullWidth
            value={underlyingFields.firstName}
            onChange={event => setUnderlyingFields(prev => ({ ...prev, firstName: event.target.value }))}
            error={!!underlyingFieldsFieldsErrors.firstName}
            helperText={underlyingFieldsFieldsErrors.firstName ? t('registration_page.error.mandatory_field') : null}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <TextField
            sx={{ backgroundColor: 'white' }}
            placeholder={t('lastname')}
            name="lname"
            autoComplete="lname"
            size="small"
            fullWidth
            value={underlyingFields.lastName}
            onChange={event => setUnderlyingFields(prev => ({ ...prev, lastName: event.target.value }))}
            error={!!underlyingFieldsFieldsErrors.lastName}
            helperText={underlyingFieldsFieldsErrors.lastName ? t('registration_page.error.mandatory_field') : null}
          />
        </Grid>
        <Grid xs={12} sm={12} md={12}>
          <Tooltip title={disableAdd ? t(normalizeKey(errorResponse?.error)) : null}>
            <div>
              <Button variant="outlined" fullWidth disabled={disableAdd} onClick={handleAddUnderlying}>
                {t('add_button')}
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid xs={12} sm={12} md={12} padding={0}>
        {fields.length !== 0 && (
          <Typography sx={{ pt: 1 }} variant="h6">
            {t('added_owners')}
          </Typography>
        )}
        <List dense disablePadding>
          {fields.map((shareholder, index) => {
            return (
              <ListItem key={shareholder.id}>
                <ListItemText
                  primary={`${shareholder.personOrOrganizationNumber} ${shareholder.firstName ?? ''} ${shareholder.lastName}`}
                  secondary={`${t('shareholder')} ${index + 1}`}
                />
                <IconButton onClick={() => remove(index)}>
                  <ClearRounded />
                </IconButton>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </>
  );
};
