export interface IAttendee {
  id: number;
  meetingId: number;
  personOrOrganizationNumber: string;
  firstName: string;
  lastName: string;
  email?: string;
  mobilePhone?: string;
  advisors?: Advisor[];
  ownerType: OwnerType;
  isRepresentingOwnShares: boolean;
  attendanceType: AttendanceType;
  isVerified: boolean;
  notificationMethod: NotificationMethod;
  underlyingShareholders: UnderlyingShareholder[];
  proxyPoAFiles: PowerOfAttorneyFile[];
}

export interface Attendee {
  meetingAttendeeId: number;
  meetingId: number;
  personOrOrganizationNumber: string;
  firstName: string;
  lastName: string;
  email?: string;
  mobilePhone?: string;
  advisors?: Advisor[];
  ownerType: number;
  isRepresentingOwnShares: boolean;
  attendanceType: number;
  notificationMethod: number;
  isVerified: boolean;
  underlyingShareholders: UnderlyingShareholder[];
  proxyPoAFiles: PowerOfAttorneyFile[];
}

export interface Advisor {
  firstName: string;
  lastName: string;
}

interface PowerOfAttorneyFile {
  fileName: string;
  fileType: string;
  file: string;
}

export interface UnderlyingShareholder {
  firstName: string;
  lastName: string;
  personOrOrganizationNumber: string;
}

export enum OwnerType {
  Proxy_IsNotRepresentingOwnShares = 'Proxy_IsNotRepresentingOwnShares',
  NotSet = 'NotSet',
  Owner = 'Owner',
  Proxy = 'Proxy',
  Guest = 'Guest',
  Advisor = 'Advisor'
}

export enum NotificationMethod {
  Phone = 'Phone',
  Letter = 'Letter',
  Email = 'Email',
  Digital = 'Digital'
}

export enum AttendanceType {
  Physical = 'Physical',
  Online = 'Online',
  Postal = 'Postal',
  ViaProxy = 'ViaProxy',
  PhysicalPostal = 'PhysicalPostal',
  OnlinePostal = 'OnlinePostal'
}

export function mapAttendeeToAttendeeDto(attendee: IAttendee): Attendee {
  return {
    meetingAttendeeId: attendee.id,
    meetingId: attendee.meetingId,
    personOrOrganizationNumber: attendee.personOrOrganizationNumber,
    firstName: attendee.firstName,
    lastName: attendee.lastName,
    ownerType: mapOwnerTypeToNumber(attendee.ownerType),
    email: attendee.email,
    mobilePhone: attendee.mobilePhone,
    isRepresentingOwnShares: attendee.isRepresentingOwnShares,
    notificationMethod: mapNotificationMethodToNumber(attendee.notificationMethod),
    attendanceType: mapAttendanceTypeToNumber(attendee.attendanceType),
    isVerified: attendee.isVerified,
    advisors: attendee.advisors ?? attendee.advisors,
    underlyingShareholders: attendee.underlyingShareholders,
    proxyPoAFiles: []
  };
}

function mapOwnerTypeToNumber(ownerType: OwnerType): number {
  switch (ownerType) {
    case OwnerType.NotSet:
      return 0;
    case OwnerType.Owner:
      return 1;
    case OwnerType.Proxy:
    case OwnerType.Proxy_IsNotRepresentingOwnShares:
      return 2;
    default:
      return 0;
  }
}
function mapAttendanceTypeToNumber(attendanceType: AttendanceType): number {
  switch (attendanceType) {
    case AttendanceType.Physical:
      return 1;
    case AttendanceType.Online:
      return 2;
    default:
      return 0;
  }
}
function mapNotificationMethodToNumber(ownerType: NotificationMethod): number {
  switch (ownerType) {
    case NotificationMethod.Phone:
      return 1;
    case NotificationMethod.Letter:
      return 2;
    case NotificationMethod.Email:
      return 3;
    case NotificationMethod.Digital:
      return 4;
    default:
      return 0;
  }
}
