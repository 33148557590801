import { Button, Card, CardActions, CardContent, CardMedia, Paper, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { useAuth } from 'hooks/useAuth';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  handleOpenAuthModal: () => void;
}

export const BankIdCard: FC<Props> = ({ handleOpenAuthModal }) => {
  const { t } = useTranslation();
  const { user, signOut } = useAuth();

  const renderSignedInCard = () => {
    return (
      <Paper variant="outlined" sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', p: 2, gap: 2 }}>
        <Paper sx={{ bgcolor: green[200], boxShadow: 'none', p: 2 }}>
          <Typography variant="h5" fontWeight={500}>
            {user?.givenName} {user?.surName}
          </Typography>
          <Typography variant="h6">{user?.personalNumber}</Typography>
        </Paper>
        <Button onClick={() => signOut()} variant="outlined" fullWidth>
          {t('bank_id.sign_out_button')}
        </Button>
      </Paper>
    );
  };

  const renderBankIDCard = () => {
    return (
      <Card variant="outlined">
        <CardMedia component="img" image="/assets/images/bankid.jpg" alt="green iguana" />
        <CardContent>
          <Typography variant="h6">{t('bank_id.card_heading')}</Typography>
          <Typography variant="subtitle2">{t('bank_id.card_text')}</Typography>
        </CardContent>
        <CardActions>
          {/*/ TODO: style this button to follow the guidelines from bank id in how it can look, e.g must be BankID casing and correct colors */}
          <Button variant="outlined" fullWidth onClick={handleOpenAuthModal}>
            {t('bank_id.sign_in_button')}
          </Button>
        </CardActions>
      </Card>
    );
  };

  return user ? renderSignedInCard() : renderBankIDCard();
};
