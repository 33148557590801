import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useLocale } from 'hooks/useLocale';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AgendaItem, getContentForLocale, isAllOptionsEmpty } from 'types/agenda';

interface AgendaItemProps {
  agendaItem: AgendaItem;
  selectedOption: string;
  handleUpdateVotes: (agendaItemId: string, option: string) => void;
}

export const AgendaItemRow: FC<AgendaItemProps> = ({ agendaItem, selectedOption, handleUpdateVotes }) => {
  const { locale } = useLocale();
  const { t } = useTranslation();

  const content = getContentForLocale(locale, agendaItem);
  const hasNoOptions = isAllOptionsEmpty(content);

  function isAgendaItemChild() {
    return agendaItem.index.includes('.');
  }

  if (hasNoOptions) {
    return (
      <Grid xs={12} sm={12} md={12}>
        <Typography fontWeight={500}>
          {agendaItem.displayIndex}. {content.question}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid xs={12} sm={6} md={6}>
        <Box sx={{ pl: isAgendaItemChild() ? 4 : 'inherit' }}>
          {agendaItem.displayIndex}. {content.question}
        </Box>
      </Grid>
      <Grid xs={12} sm={6} md={6} display={'flex'} justifyContent={'flex-end'}>
        {agendaItem.isResolution && (
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedOption}
              onChange={(_e, value) => handleUpdateVotes(agendaItem.agendaItemId, value)}
            >
              <FormControlLabel value="option1" control={<Radio />} label={content.option1} />
              <FormControlLabel value="option2" control={<Radio />} label={content.option2} />
              <FormControlLabel value="option3" control={<Radio />} label={content.option3} />
            </RadioGroup>
          </FormControl>
        )}
      </Grid>
      {content.suggestion && (
        <Grid xs={12} sm={12} md={12} sx={{ pl: 4 }}>
          {t('vote_page.suggestion', { suggestion: content.suggestion })}
        </Grid>
      )}
    </>
  );
};
