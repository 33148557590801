import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from 'utils/fetch';

export function useDeletePoaMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['deletePoa'],
    mutationFn: (fileId: string) => deletePoa(fileId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['poas'] });
    }
  });
}

async function deletePoa(fileId: string) {
  return await api.delete(`/external/DeletePoa/${fileId}`);
}
