import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded';
import OpenInNewRounded from '@mui/icons-material/OpenInNewRounded';
import PrintRounded from '@mui/icons-material/PrintRounded';
import { Box, Button, Card, CardActions, CardContent, Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { green } from '@mui/material/colors';
import { useAuth } from 'hooks/useAuth';
import { useLocale } from 'hooks/useLocale';
import { usePostalVotingFileQuery } from 'queries/usePostalVotingFileQuery';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IMeeting } from 'types/meeting';
import { getLocaleDateString, isDatePassed } from 'utils/date';

interface Props {
  meeting: IMeeting;
}

export const MeetingCard = ({ meeting }: Props) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { user } = useAuth();
  const {
    id,
    clientName,
    startTime,
    lastPostalVotingDate,
    lastRegistrationDate,
    registrationDate,
    postalVotingDate,
    postalVotingFiles
  } = meeting;
  const [isDownloadButtonClicked, setIsDownloadButtonClicked] = useState<boolean>(false);
  const { data: postalVotingFile } = usePostalVotingFileQuery(id, locale, isDownloadButtonClicked);
  const isPostalVotingFilesEmpty = !Array.isArray(postalVotingFiles) || postalVotingFiles.length < 1;

  function renderUserStatusSection() {
    if (!registrationDate && !postalVotingDate) {
      return null;
    }

    return (
      <Paper variant="outlined" sx={{ p: 1, bgcolor: green[200], border: 'none' }}>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <CheckCircleOutlineRounded sx={{ color: 'white', fontSize: 28 }} />
          <Box>
            {renderUserStatusText(t('meetings_page.registered'), registrationDate)}
            {renderUserStatusText(t('meetings_page.postal_voted'), postalVotingDate)}
          </Box>
        </Stack>
      </Paper>
    );
  }

  function renderUserStatusText(title: string, date: Date | null) {
    if (!date) {
      return null;
    }

    return (
      <Typography variant="subtitle2">
        {title}: {getLocaleDateString(date, locale)}
      </Typography>
    );
  }

  function getNoticeLink(): string {
    const svLink = meeting.notificationLinkSwedish;
    const enLink = meeting.notificationLinkEnglish;

    switch (locale) {
      case 'sv':
        return svLink ?? enLink ?? '';
      case 'en':
        return enLink ?? svLink ?? '';
      default:
        return '';
    }
  }

  function renderPostalVotingDate(): ReactNode {
    return (
      <>
        <Typography variant="subtitle2" color="text.secondary">
          {t('meetings_page.postal_voting_deadline')}
        </Typography>
        <Typography variant="body1" fontSize={16} fontWeight={500}>
          {lastPostalVotingDate
            ? getLocaleDateString(lastPostalVotingDate, locale)
            : t('meetings_page.no_postal_voting')}
        </Typography>
      </>
    );
  }

  function isPostalVotingDisabled(pin: string | undefined, lastPostalVotingDate: Date) {
    if (isDatePassed(lastPostalVotingDate) || !pin) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (isDownloadButtonClicked && postalVotingFile) {
      handleDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadButtonClicked, postalVotingFile]);

  function handleDownload() {
    try {
      if (postalVotingFile) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(postalVotingFile);
        link.download = `${clientName} - ${getLocaleDateString(startTime, locale)}.pdf`;
        link.click();
        setIsDownloadButtonClicked(false);
      }
    } catch (error) {
      setIsDownloadButtonClicked(false);
      console.error(error);
    }
  }

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardContent>
        <Stack sx={{ pb: 2 }}>
          <Typography variant="h6" fontWeight={700}>
            {clientName}
          </Typography>

          <Typography variant="subtitle2" color="text.secondary">
            {t('meetings_page.meeting_date')}
          </Typography>
          <Typography variant="body1" fontSize={16} fontWeight={500}>
            {getLocaleDateString(startTime, locale)}
          </Typography>
        </Stack>
        <Grid container spacing={1}>
          <Grid xs={12} sm={6} md={6}>
            <Typography variant="subtitle2" color="text.secondary">
              {t('meetings_page.notification_deadline')}
            </Typography>
            <Typography variant="body1" fontSize={16} fontWeight={500}>
              {getLocaleDateString(lastRegistrationDate, locale)}
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            {renderPostalVotingDate()}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          <Button
            component={Link}
            variant="outlined"
            size="small"
            to={`/meeting/${id}/register`}
            disabled={isDatePassed(lastRegistrationDate)}
          >
            {t('register_button')}
          </Button>
          {lastPostalVotingDate && (
            <Button
              component={Link}
              variant="outlined"
              size="small"
              to={`/meeting/${id}/vote`}
              disabled={isPostalVotingDisabled(user?.personalNumber, lastPostalVotingDate)}
            >
              {t('postal_voting_button')}
            </Button>
          )}
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<PrintRounded />}
              color="info"
              disabled={isDatePassed(startTime) || isPostalVotingFilesEmpty}
              onClick={() => {
                setIsDownloadButtonClicked(true);
              }}
            >
              {t('print_button')}
            </Button>

            <Button
              size="small"
              variant="outlined"
              href={getNoticeLink()}
              target="_blank"
              startIcon={<OpenInNewRounded />}
              color="info"
              disabled={!getNoticeLink()}
            >
              {t('notice_button')}
            </Button>
          </Box>
        </Box>
      </CardActions>
      {renderUserStatusSection()}
    </Card>
  );
};
