import { Box, Container } from '@mui/material';
import { Footer } from 'components/Footer';
import { TopNav } from 'components/TopNav';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  return (
    <>
      <main>
        <TopNav />
        <Box sx={{ pt: 12 }}>
          <Container sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Outlet />
          </Container>
        </Box>
      </main>
      <footer>
              <Footer />
      </footer>
    </>
  );
};
