import { CircularProgress, Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { green } from '@mui/material/colors';
import { RegisterForm } from 'components/RegisterForm';
import { useAuth } from 'hooks/useAuth';
import { useLocale } from 'hooks/useLocale';
import { useMeetingQuery } from 'queries/useMeetingQuery';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { OwnerType } from 'types/attendee';
import { IMeeting, Type } from 'types/meeting';
import { getLocaleDateString } from 'utils/date';

const RegistrationPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { locale } = useLocale();
  const meetingId = Number(params.meetingId);
  const { user } = useAuth();
  const { data: meeting, isLoading } = useMeetingQuery(meetingId, user?.personalNumber);

  function renderAlreadyRegistered() {
    if (!meeting?.registrationDate || meeting.ownerType === OwnerType.NotSet) {
      return null;
    }

    return (
      <Paper variant="outlined" sx={{ p: 1, bgcolor: green[200], border: 'none' }}>
        <Typography variant="h6">{t('registration_page.registering_done')}</Typography>
        <Typography variant="subtitle2">
          {t('registration_page.registeration_date')}: {getLocaleDateString(meeting.registrationDate, locale)}
        </Typography>
      </Paper>
    );
  }

  function renderFormHeading(meeting: IMeeting) {
    const type = getMeetingTypeText(meeting.type);
    const date = getLocaleDateString(meeting.startTime, locale);

    return (
      <Stack>
        <Typography variant="h5" fontWeight={500}>
          {type} {t('registration_page.in')} {meeting.clientName}
        </Typography>
        <Typography variant="subtitle2">{date}</Typography>
      </Stack>
    );
  }

  function getMeetingTypeText(type: Type): string {
    if (type === Type.AGM) {
      return t('registration_page.annual_meeting');
    }

    return t('registration_page.extra_meeting');
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!meeting) {
    return <Typography variant="h6">{t('meetings_page.meeting_notfound')}</Typography>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12}>
          <Typography variant="h4" fontWeight={500}>
            {t('registration_page.heading')}
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} md={12}>
          <Stack gap={2}>
            <Typography variant="subtitle2">
              {t('registration_page.last_notice_info', {
                lastRegistrationDate: getLocaleDateString(meeting.lastRegistrationDate, locale),
                lastVotingRegistrationDate: getLocaleDateString(meeting.lastVotingRegistrationDate, locale)
              })}
            </Typography>
            <Typography variant="subtitle2">{t('registration_page.email_confirmation_info')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      {renderAlreadyRegistered()}
      {renderFormHeading(meeting)}
      <RegisterForm meeting={meeting} pin={user?.personalNumber} />
    </>
  );
};

export default RegistrationPage;
