import { CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import Routes from 'routes/routes';
import { Providers } from './providers';

const App = () => {
  return (
    <Providers>
      <Suspense fallback={<CircularProgress />}>
        <Routes />
      </Suspense>
    </Providers>
  );
};

export default App;
