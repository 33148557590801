import { AuthContext } from 'providers/auth';
import { useContext } from 'react';

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context == null) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
};
