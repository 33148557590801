import { useQueryClient } from '@tanstack/react-query';
import { FC, ReactNode, createContext, useEffect, useState } from 'react';

interface BankIdUser {
  personalNumber: string;
  givenName: string;
  surName: string;
}

interface Auth {
  user: BankIdUser | null;
  signIn: (user: BankIdUser) => void;
  signOut: () => void;
}

interface Props {
  children?: ReactNode;
}

export const AuthContext = createContext<Auth>({
  user: null,
  signIn: () => {
    console.warn('signIn has been called without a Context Provider');
  },
  signOut: () => {
    console.warn('signOut has been called without a Context Provider');
  }
});

export const AuthProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<BankIdUser | null>(getUserLocaleStorage());

  const signOut = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const signIn = (user: BankIdUser) => {
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  };

  function getUserLocaleStorage(): BankIdUser | null {
    const userObjectString = localStorage.getItem('user');

    if (typeof userObjectString !== 'string') {
      return null;
    }

    return JSON.parse(userObjectString);
  }

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['meetings', user?.personalNumber] });
  }, [user?.personalNumber, queryClient]);

  return <AuthContext.Provider value={{ user, signIn, signOut }}>{children}</AuthContext.Provider>;
};
