import { List, ListItemButton, ListItemText, ListSubheader, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const RelatedContent = () => {
  const { t } = useTranslation();

  return (
    <Paper variant="outlined">
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        dense
        subheader={<ListSubheader>{t('related_content.heading')}</ListSubheader>}
      >
        <ListItemButton>
          <ListItemText
            primary={t('related_content.about_euroclear')}
            primaryTypographyProps={{ fontSize: 14, color: 'primary' }}
          />
        </ListItemButton>

        <ListItemButton>
          <ListItemText
            primary={t('related_content.privacy_policy')}
            primaryTypographyProps={{ fontSize: 14, color: 'primary' }}
          />
        </ListItemButton>
      </List>
    </Paper>
  );
};
