import { useQuery } from '@tanstack/react-query';
import { api } from 'utils/fetch';

export function usePostalVotingFileQuery(meetingId: number, languageCode: string, isDownloadButtonClicked: boolean) {
  return useQuery({
    queryKey: ['postalVotingFile'],
    queryFn: () => getPostalVotingFile(meetingId, languageCode),
    enabled: isDownloadButtonClicked
  });
}

async function getPostalVotingFile(meetingId: number, languageCode: string): Promise<Blob | undefined> {
  try {
    const response = await api.get(`/external/postalvotingfile/${meetingId}/${languageCode}`, {
      params: {
        meetingId,
        languageCode
      },
      responseType: 'blob'
    });

    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/json'
      });
      return Promise.resolve(blob);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
