export function isDatePassed(date: Date): boolean {
  const currentDate = new Date();

  const currentDateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  const compareDateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  return currentDateWithoutTime > compareDateWithoutTime;
}

export function getLocaleDateString(date: Date | null, locale: string): string {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleDateString(locale, { day: 'numeric', month: 'long', year: 'numeric' });
}
