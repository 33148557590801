import { useQuery } from '@tanstack/react-query';
import { mapMeetingShortInfoDtoToInternal } from 'types/meeting';
import { api } from 'utils/fetch';

export function useMeetingsQuery(pin?: string | undefined) {
  return useQuery({ queryKey: ['meetings', pin], queryFn: () => getMeetings(pin) });
}

async function getMeetings(pin?: string | undefined) {
  const response = await api.get(`/external/meetings`, {
    params: {
      pin
    }
  });

  return mapMeetingShortInfoDtoToInternal(response.data);
}
