import ClearRounded from '@mui/icons-material/ClearRounded';
import {
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AddUnderlyingShareholders } from 'components/AddUnderlyingShareholders';
import { RepresentsDropdown } from 'components/RepresentsDropdown';
import { FC, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { FileUploadCard } from 'components/FileUploadCard';
import { t } from 'i18next';
import Personnummer from 'personnummer';
import { useRegistrationMutation } from 'queries/useRegistrationMutation';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Advisor, AttendanceType, IAttendee, NotificationMethod, OwnerType } from 'types/attendee';
import { IMeeting } from 'types/meeting';
import formatPINAndOrgNr from 'utils/pin';
import { z } from 'zod';

const PersonSchema = z.object({
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1)
});

const ShareholderSchema = z.object({
  personOrOrganizationNumber: z
    .string()
    .min(10)
    .max(13)
    .refine(data => Personnummer.valid(data)),
  firstName: z.string().trim(),
  lastName: z.string().trim().min(1)
});

const FormSchema = ShareholderSchema.extend({
  email: z.string().trim().email(),
  mobilePhone: z.string(),
  ownerType: z.nativeEnum(OwnerType),
  advisors: z.array(PersonSchema),
  underlyingShareholders: z.array(ShareholderSchema)
});

export type RegisterForm = z.infer<typeof FormSchema>;

interface RegisterFormProps {
  meeting: IMeeting;
  pin: string | undefined;
}

export const RegisterForm: FC<RegisterFormProps> = ({ meeting, pin }) => {
  const params = useParams();
  const meetingId = Number(params.meetingId);
  const registrationMutation = useRegistrationMutation();

  const [advisorFields, setAdvisorFields] = useState<Advisor>({ firstName: '', lastName: '' });
  const [advisorFieldsErrors, setAdvisorFieldsErrors] = useState<Advisor>({ firstName: '', lastName: '' });
  const { handleSubmit, control, reset, watch } = useForm<RegisterForm>({
    defaultValues: {
      personOrOrganizationNumber: pin?.toString() ?? '',
      firstName: meeting.firstName ?? '',
      lastName: meeting.lastName ?? '',
      email: meeting.email ?? '',
      advisors: meeting.advisors ?? [],
      underlyingShareholders:
        meeting.underlyingShareholders?.map(s => {
          return {
            personOrOrganizationNumber: s.personOrOrganizationNumber,
            firstName: s.firstName,
            lastName: s.lastName
          } as Advisor;
        }) ?? [],
      mobilePhone: meeting.phoneNumber ?? '',
      ownerType: meeting.ownerType
    },
    resolver: zodResolver(FormSchema)
  });

  const isProxy =
    watch('ownerType') === OwnerType.Proxy_IsNotRepresentingOwnShares || watch('ownerType') === OwnerType.Proxy;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'advisors'
  });

  const {
    fields: underlyingFields,
    append: appendUnderlying,
    remove: removeUnderlying
  } = useFieldArray({
    control,
    name: 'underlyingShareholders'
  });

  const onSubmit: SubmitHandler<RegisterForm> = data => {
    const result = FormSchema.safeParse(data);

    if (!result.success) {
      console.error(result.error.format());
      return;
    }

    const { firstName, lastName, ownerType, underlyingShareholders, advisors, email, mobilePhone } = data;

    const attendeeId = meeting?.attendeeId ?? 0;
    const underlyingPinUnformated = underlyingShareholders.map(y => {
      return {
        firstName: y.firstName,
        lastName: y.lastName,
        personOrOrganizationNumber: y.personOrOrganizationNumber.replace('-', '')
      };
    });

    const attendee: IAttendee = {
      id: attendeeId,
      meetingId,
      personOrOrganizationNumber: data.personOrOrganizationNumber.replace('-', ''),
      firstName,
      lastName,
      ownerType: (ownerType as string) === OwnerType.Proxy_IsNotRepresentingOwnShares ? OwnerType.Proxy : ownerType,
      isRepresentingOwnShares: (ownerType as string) !== OwnerType.Proxy_IsNotRepresentingOwnShares,
      notificationMethod: NotificationMethod.Digital,
      attendanceType: AttendanceType.Physical,
      isVerified: !!pin,
      advisors,
      email: email,
      mobilePhone: mobilePhone,
      underlyingShareholders: ownerType !== OwnerType.Owner ? underlyingPinUnformated : [],
      proxyPoAFiles: []
    };

    registrationMutation.mutate(attendee);

    reset();
  };

  const handleAddAdvisor = () => {
    if (isAdvisorFieldsErrors()) {
      return;
    }

    append(advisorFields);
    setAdvisorFields({ firstName: '', lastName: '' });
  };

  const isAdvisorFieldsErrors = () => {
    if (advisorFields.firstName.length > 0 && advisorFields.lastName.length > 0) {
      setAdvisorFieldsErrors({ firstName: '', lastName: '' });

      return false;
    }

    if (advisorFields.firstName.length == 0) {
      setAdvisorFieldsErrors(prev => ({ ...prev, firstName: 'required' }));
    }

    if (advisorFields.lastName.length == 0) {
      setAdvisorFieldsErrors(prev => ({ ...prev, lastName: 'required' }));
    }

    return true;
  };

  function renderRepresentsSection() {
    if (!pin) {
      return null;
    }

    return (
      <>
        <Grid xs={12}>
          <Typography variant="h6">{t('registration_page.representHeading')}</Typography>
        </Grid>
        <Grid xs={12} sm={12} md={12}>
          <RepresentsDropdown control={control} />
        </Grid>
      </>
    );
  }

  function renderAddUnderlying() {
    if (isProxy) {
      return (
        <>
          <Grid xs={12}>
            <Typography variant="h6">{t('registration_page.representOwnersHeading')}</Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <Typography variant="body2">{t('registration_page.supplyRepresentedInfo')}</Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <AddUnderlyingShareholders
              fields={underlyingFields}
              append={appendUnderlying}
              remove={removeUnderlying}
              proxyId={meeting?.attendeeId ?? 0}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12}>
            <FileUploadCard
              meetingId={meetingId}
              meetingAttendeeId={meeting?.attendeeId ?? 0}
              pin={pin ?? watch('personOrOrganizationNumber')}
            />
          </Grid>
        </>
      );
    }
  }

  if (registrationMutation.isPending) {
    return <CircularProgress />;
  }

  if (registrationMutation.isSuccess) {
    return <p>{t('registration_page.thanks')}</p>;
  }

  if (registrationMutation.isError) {
    return <p>{registrationMutation.error.message}</p>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <Grid container spacing={2} xs={12} sm={12} md={6}>
          <Grid xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="personOrOrganizationNumber"
              defaultValue={''}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <TextField
                    placeholder={t('registration_page.form.pin')}
                    name="bday"
                    autoComplete="bday"
                    value={value ? formatPINAndOrgNr(value.replace('-', '')) : ''}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!error}
                    required
                    helperText={
                      error?.message || (value && !Personnummer.valid(value))
                        ? t('registration_page.error.invalid_pin')
                        : null
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            <Controller
              control={control}
              name="firstName"
              defaultValue={''}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <TextField
                    placeholder={t('registration_page.form.first_name')}
                    name="fname"
                    autoComplete="fname"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!error}
                    required
                    helperText={error?.message ? t('registration_page.error.mandatory_field') : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            <Controller
              control={control}
              name="lastName"
              defaultValue={''}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <TextField
                    placeholder={t('registration_page.form.last_name')}
                    name="lname"
                    autoComplete="lname"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!error}
                    required
                    helperText={error?.message ? t('registration_page.error.mandatory_field') : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="email"
              defaultValue={''}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <TextField
                    placeholder={t('registration_page.form.email')}
                    name="email"
                    autoComplete="email"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message ? t('registration_page.error.invalid_email') : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="mobilePhone"
              defaultValue={''}
              render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                  <TextField
                    placeholder={t('registration_page.form.mobile_number')}
                    name="mobilePhone"
                    autoComplete="mobilePhone"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message ? error.message : null}
                  />
                );
              }}
            />
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">{t('registration_page.advisors')}</Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12} padding={0}>
            <List dense disablePadding>
              {fields.map((advisor, index) => {
                return (
                  <ListItem key={window.crypto.randomUUID()}>
                    <ListItemText
                      primary={` ${advisor.firstName} ${advisor.lastName}`}
                      secondary={`${t('registration_page.advisor')} ${index + 1}`}
                    />
                    <IconButton onClick={() => remove(index)}>
                      <ClearRounded />
                    </IconButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          {fields.length < 2 && (
            <Grid xs={12} sm={6} md={4.5}>
              <TextField
                placeholder={t('registration_page.form.first_name')}
                name="fname"
                autoComplete="fname"
                size="small"
                fullWidth
                error={!!advisorFieldsErrors.firstName}
                helperText={advisorFieldsErrors.firstName ? t('registration_page.error.mandatory_field') : null}
                value={advisorFields.firstName}
                onChange={event => setAdvisorFields(prev => ({ ...prev, firstName: event.target.value }))}
              />
            </Grid>
          )}
          {fields.length < 2 && (
            <Grid xs={12} sm={6} md={4.5}>
              <TextField
                placeholder={t('registration_page.form.last_name')}
                name="lname"
                autoComplete="lname"
                size="small"
                fullWidth
                error={!!advisorFieldsErrors.lastName}
                helperText={advisorFieldsErrors.lastName ? t('registration_page.error.mandatory_field') : null}
                value={advisorFields.lastName}
                onChange={event => setAdvisorFields(prev => ({ ...prev, lastName: event.target.value }))}
              />
            </Grid>
          )}
          {fields.length < 2 && (
            <Grid xs={12} sm={12} md={3}>
              <Button variant="outlined" fullWidth onClick={handleAddAdvisor}>
                {t('add_button')}
              </Button>
            </Grid>
          )}
          {renderRepresentsSection()}
          {renderAddUnderlying()}
          <Grid xs={12} sm={12} md={12}>
            <Button type="submit" variant="contained" fullWidth>
              {t('registration_page.send_notification_button')}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};
