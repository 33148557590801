import { useTranslation } from 'react-i18next';

export const useLocale = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const toggleLocale = () => {
    if (locale === 'sv') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('sv');
    }
  };

  return { locale, toggleLocale };
};
