import { Advisor, OwnerType, UnderlyingShareholder } from 'types/attendee';
import { PostalVotingFile } from 'types/postalVotingFile';

export interface IMeeting {
  id: number;
  attendeeId: number;
  name: string;
  clientId: number;
  clientName: string;
  startTime: Date;
  endTime: Date;
  type: Type;
  status: Status;
  isAllDay: boolean;
  ownerType: OwnerType;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  isRepresentingOwnShares: boolean;
  advisors: Advisor[];
  underlyingShareholders: UnderlyingShareholder[];
  notificationLinkEnglish?: string;
  notificationLinkSwedish?: string;
  postalVotingDate: Date | null;
  registrationDate: Date | null;
  lastPostalVotingDate: Date | null;
  lastRegistrationDate: Date;
  lastVotingRegistrationDate: Date;
  postalVotingFiles: PostalVotingFile[] | null;
}

export interface Meeting {
  meetingId: number;
  meetingAttendeeId: number;
  meetingName?: string;
  clientId: number;
  clientName?: string;
  meetingStartTime: string;
  meetingEndTime: string;
  meetingType: MeetingType;
  meetingStatus: MeetingStatus;
  isAllDay: boolean;
  ownerType: number;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  isRepresentingOwnShares: boolean;
  advisors: Advisor[];
  underlyingShareholders: UnderlyingShareholder[];
  notificationLinkEnglish?: string;
  notificationLinkSwedish?: string;
  postalVotingDate: string | null;
  registrationDate: string | null;
  lastPostalVotingDate: string | null;
  lastRegistrationDate: string;
  lastVotingRegistrationDate: string;
  postalVotingFiles: PostalVotingFile[] | null;
}

export const enum MeetingType {
  AnnualGeneralMeeting,
  ExtraordinaryGeneralMeeting
}

export enum Type {
  AGM = 'AGM',
  EGM = 'EGM'
}

export enum SortBy {
  ClientName = 'ClientName',
  MeetingDate = 'MeetingDate',
  DeadlineNotification = 'DeadlineNotification',
  DeadlinePostalVote = 'DeadlinePostalVote'
}

function mapMeetingTypeToType(meetingType: number): Type {
  switch (meetingType) {
    case 1:
      return Type.AGM;
    case 2:
      return Type.EGM;
    default:
      return Type.AGM;
  }
}

export const enum MeetingStatus {
  Ongoing = 2,
  Completed = 3
}

export enum Status {
  Ongoing = 'Ongoing',
  Completed = 'Completed'
}

function mapMeetingStatusToStatus(meetingStatus: MeetingStatus): Status {
  switch (meetingStatus) {
    case MeetingStatus.Ongoing:
      return Status.Ongoing;
    case MeetingStatus.Completed:
      return Status.Completed;
    default:
      return Status.Ongoing;
  }
}

export function mapMeetingShortInfoDtoToInternal(dto: Meeting[]): IMeeting[] {
  return dto.map(meeting => {
    return {
      id: meeting.meetingId,
      attendeeId: meeting.meetingAttendeeId,
      name: meeting.meetingName ?? '',
      startTime: new Date(meeting.meetingStartTime),
      endTime: new Date(meeting.meetingEndTime),
      type: mapMeetingTypeToType(meeting.meetingType),
      status: mapMeetingStatusToStatus(meeting.meetingStatus),
      isAllDay: meeting.isAllDay,
      ownerType: mapOwnerTypeToInternal(meeting.ownerType, meeting.isRepresentingOwnShares),
      phoneNumber: meeting.phoneNumber,
      email: meeting.email,
      firstName: meeting.firstName,
      lastName: meeting.lastName,
      isRepresentingOwnShares: meeting.isRepresentingOwnShares,
      advisors: meeting.advisors,
      underlyingShareholders: meeting.underlyingShareholders,
      clientId: meeting.clientId,
      clientName: meeting.clientName ?? '',
      notificationLinkEnglish: meeting.notificationLinkEnglish,
      notificationLinkSwedish: meeting.notificationLinkSwedish,
      postalVotingDate: meeting.postalVotingDate ? new Date(meeting.postalVotingDate) : null,
      registrationDate: meeting.registrationDate ? new Date(meeting.registrationDate) : null,
      lastPostalVotingDate: meeting.lastPostalVotingDate ? new Date(meeting.lastPostalVotingDate) : null,
      lastRegistrationDate: new Date(meeting.lastRegistrationDate),
      lastVotingRegistrationDate: new Date(meeting.lastVotingRegistrationDate),
      postalVotingFiles: meeting.postalVotingFiles ?? null
    };
  });
}

function mapOwnerTypeToInternal(ownerType: number, isRepresentingOwnShares: boolean): OwnerType {
  switch (ownerType) {
    case 0:
      return OwnerType.NotSet;
    case 1:
      return OwnerType.Owner;
    case 2:
      return isRepresentingOwnShares ? OwnerType.Proxy : OwnerType.Proxy_IsNotRepresentingOwnShares;
    case 3:
      return OwnerType.Guest;
    case 4:
      return OwnerType.Advisor;
    default:
      return OwnerType.NotSet;
  }
}

export function mapOwnerTypeToNumber(ownerType: OwnerType): number {
  switch (ownerType) {
    case OwnerType.NotSet:
      return 0;
    case OwnerType.Owner:
      return 1;
    case OwnerType.Proxy:
    case OwnerType.Proxy_IsNotRepresentingOwnShares:
      return 2;
    case OwnerType.Guest:
      return 3;
    case OwnerType.Advisor:
      return 4;
    default:
      return 0;
  }
}
