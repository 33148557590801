import { useMutation } from '@tanstack/react-query';
import { Voting, mapVotingToDto } from 'types/agenda';
import { api } from 'utils/fetch';

export function useVoteMutation() {
  return useMutation({
    mutationKey: ['vote'],
    mutationFn: ({ voting, meetingId }: { voting: Voting; meetingId: number }) => postVotes(voting, meetingId)
  });
}

async function postVotes(voting: Voting, meetingId: number) {
  return await api.patch(`/external/meetings/${meetingId}/voting`, mapVotingToDto(voting));
}
