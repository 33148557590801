import { useQuery } from '@tanstack/react-query';
import { IMeeting, mapMeetingShortInfoDtoToInternal } from 'types/meeting';
import { api } from 'utils/fetch';

export function useMeetingQuery(meetingId?: number, pin?: string | null) {
  return useQuery({
    queryKey: ['meetings', pin, meetingId],
    queryFn: () => getMeeting(meetingId, pin?.replace('-', ''))
  });
}

async function getMeeting(meetingId?: number, pin?: string | null): Promise<IMeeting> {
  const response = await api.get(`/external/meetings`, {
    params: {
      pin,
      meetingId
    }
  });

  return mapMeetingShortInfoDtoToInternal(response.data)[0];
}
