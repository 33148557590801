import Organisationsnummer from 'organisationsnummer';
import Personnummer from 'personnummer';

function validatePinAndOrgNumber(value: string) {
  if (Personnummer.valid(value) || isOrgNumberValid(value)) {
    return true;
  }
}
function isOrgNumberValid(orgnr: string): boolean {
  return Organisationsnummer.valid(orgnr);
}
export default validatePinAndOrgNumber;
