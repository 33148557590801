import { MenuItem, TextField } from '@mui/material';
import { RegisterForm } from 'components/RegisterForm';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OwnerType } from 'types/attendee';

interface Props {
  control: Control<RegisterForm, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const RepresentsDropdown = ({ control }: Props) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="ownerType"
      defaultValue={OwnerType.Owner}
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => {
        return (
          <TextField
            label={t('registration_page.iWannaRep')}
            name="ownerType"
            select
            value={value ?? OwnerType.Owner}
            onChange={onChange}
            fullWidth
            size="small"
            variant="outlined"
            error={!!error}
            inputRef={ref}
          >
            <MenuItem key={OwnerType.Owner} value={OwnerType.Owner}>
              {t('registration_page.ownShares')}
            </MenuItem>
            <MenuItem key={OwnerType.Proxy} value={OwnerType.Proxy}>
              {t('registration_page.ownAndOthersShares')}
            </MenuItem>
            <MenuItem
              key={OwnerType.Proxy_IsNotRepresentingOwnShares}
              value={OwnerType.Proxy_IsNotRepresentingOwnShares}
            >
              {t('registration_page.othersShares')}
            </MenuItem>
          </TextField>
        );
      }}
    />
  );
};
