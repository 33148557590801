import { createTheme } from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';

const palette = createPalette({
  mode: 'light',
  primary: {
    main: '#c33', // euroclear red
    contrastText: '#fff'
  }
});

export const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    }
  },
  palette
});
