import { Paper, Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { IMeeting } from 'types/meeting';
import { MeetingCard } from './MeetingCard';

export const MeetingsList = ({ meetings, isLoading }: { meetings?: IMeeting[]; isLoading: boolean }) => {
  const { t } = useTranslation();

  const renderMeetings = () => {
    if (!isLoading && !meetings) {
      return (
        <Paper
          variant="outlined"
          sx={{ display: 'flex', width: '100%', p: 2, borderRadius: 2, justifyContent: 'center' }}
        >
          <Typography variant="h6">{t('meetings_page.error_fetch')}</Typography>
        </Paper>
      );
    }

    if (isLoading) {
      return <MeetingListSkeleton />;
    }

    if (!meetings) {
      return (
        <Paper
          variant="outlined"
          sx={{ display: 'flex', width: '100%', p: 2, borderRadius: 2, justifyContent: 'center' }}
        >
          <Typography variant="h6">{t('meetings_page.error_fetch')}</Typography>
        </Paper>
      );
    }

    if (meetings.length === 0) {
      return (
        <Paper
          variant="outlined"
          sx={{ display: 'flex', width: '100%', p: 2, borderRadius: 2, justifyContent: 'center' }}
        >
          <Typography variant="h6">{t('meetings_page.no_meetings')}</Typography>
        </Paper>
      );
    }

    return (
      <Grid container spacing={2}>
        {meetings.map(meeting => (
          <Grid xs={12} sm={12} md={6} key={meeting.id}>
            <MeetingCard meeting={meeting} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return renderMeetings();
};

const MeetingListSkeleton = () => {
  const arr = new Array(10).fill(0);
  return (
    <Grid container spacing={2}>
      {arr.map((_, key) => (
        <Grid xs={12} sm={12} md={6} key={key}>
          <Skeleton variant="rounded" animation="pulse" height={225}></Skeleton>
        </Grid>
      ))}
    </Grid>
  );
};
